import { CharacterId } from './Characters'
import { ClothingStyle } from './ClothingStyle'
import { EpisodesRow } from './EpisodesRow'
import { SceneId } from './Scenes'

export type NonEmptyArray<T> = [T, ...T[]]

export enum ChannelPointRewardIdent {
  DRINK = 'drink',
  TOPIC = 'topic',
  YATO_DEMON = 'yato demon',
  ORIGAMI_HYPER = 'origami hyper',
  CHIPMUNK = 'chipmunk',
  SMOKER = 'smoker',
  NIJI = 'niji',
  STAR_TREK = 'star trek',
  SEQUEL = 'sequel',
  SKIP_AUTOMATIC = 'skip automatic',
  SKIP_PROMPTED = 'skip prompted',
}

export interface SceneDef {
  id: SceneId
  name: string
  image: string
  imageNiji5: string
  aliases: string[]
  description: string
  clothingStyle: ClothingStyle
}

export enum ScriptLineType {
  LOCATION = 'location',
  DIALOG = 'dialog',
  NARRATION = 'narration',
}

export interface LogsRow {
  id: number
  entity_id: number | null
  entity_type: number | null
  log_type: string | null
  log: unknown[]
}

export interface LocationScriptLine {
  type: ScriptLineType.LOCATION
  data: {
    location: string
    sceneId?: SceneId
  }
}

export interface NarrationScriptLine {
  type: ScriptLineType.NARRATION
  data: {
    text: string
    voicefile?: string
  }
}

export interface DialogScriptLine {
  type: ScriptLineType.DIALOG
  data: {
    actor: string
    text: string
    voicefile?: string
    emotionText?: string
    emotion?: string
  }
}

export type ScriptLine = LocationScriptLine | NarrationScriptLine | DialogScriptLine

export enum ModifierName {
  PITCH = 'pitch',
  YATO_DEMON = 'yato_demon',
  ORIGAMI_HYPER = 'origami_hyper',
  STAR_TREK = 'star_trek',
  NIJI_5_BG = 'niji_5_bg',
}

export interface ModifierRow {
  id: number
  name: ModifierName
  value: number
}

export const getModifierValue = (modifiers: ModifierRow[], modifierName: ModifierName): number => {
  const modifier = modifiers.find(mod => mod.name === modifierName)
  return modifier ? modifier.value : 0
}

export enum PollProcessingStatus {
  ACTIVE = 'active',
  WAITING = 'waiting',
  FINISHED = 'finished',
  CREATING_SCRIPT = 'creating_script',
  PROCESSING_SCRIPT = 'processing_script',
}

export interface Highscore {
  title: string
  entries: HighscoreEntry[]
}

export interface HighscoreEntry {
  name: string
  count: number
}

export interface QueueInfoData {
  unplayedEpisodesWithoutPrompt: number
  unplayedEpisodesWithPrompt: number
  episodesProcessing: number
  promptsQueued: number
  testScriptsQueued: number
  promptHighschore: {
    today: Highscore
    month: Highscore
    alltime: Highscore
  }
  pollsQueued: number
  episodes: EpisodesRow[]
  modifiers: ModifierRow[]
  pollProcessing: PollsRow | null
  pollProcessingStatus: PollProcessingStatus | null
  fanartItems: FanartRow[]
  version: string
  chatMessages: TwitchMessagesRow[]
}

export interface EpisodesInfoData {
  listedEpisodes: EpisodesRow[]
  playlistEpisodes: EpisodesRow[]
  totalEpisodesCount: number
}

export interface PollChoice {
  letter: string // 'A' | 'B' | 'C' | 'D' | 'E'
  text: string
  voicefile?: string
}

export interface PollData {
  title: {
    text: string,
    voicefile?: string
  }
  choices: PollChoice[]
}

export enum PollStatus {
  QUEUED = 'queued',
  PROCESSING = 'processing',
  PROCESSED = 'processed',
}

export interface PollConversationMessage {
  role: string
  content: string
  name?: string
  emotion?: string
  voicefile?: string
  status: 'queued' | 'processed'
}

export interface PollConversation {
  messages: PollConversationMessage[]
}

export interface PollsRow {
  id: number
  poll_data: PollData
  twitch_poll_id: string | null
  twitch_poll_data: TwitchPollData | null
  created: string
  finished: string|null
  status: PollStatus
  poll_character_id: CharacterId
  winning_choice: string | null
  conversation: PollConversation | null
}

export interface TwitchPollChoice {
  id: string
  title: string
  totalVotes: number
  channelPointsVotes: number
}

export interface TwitchPollData {
  id: string
  broadcasterId: string
  broadcasterName: string
  broadcasterDisplayName: string
  title: string
  isChannelPointsVotingEnabled: boolean
  channelPointsPerVote: number
  status: string
  durationInSeconds: number
  startDate: string // json date
  endDate: string // json date
  choices: TwitchPollChoice[]
}

export interface FanartRow {
  id: number
  url: string
  active: boolean
}

export interface TwitchMessagesRow {
  id: number
  created: string // json date
  username: string
  text: string
  type: string | null
}

export interface TwitchMessageCommentsRow {
  id: number
  twitch_message_id: number
  character_id: CharacterId
  message: { text: string, voicefile?: string }
  comment: { text: string, emotion: string, voicefile: string }
}

export interface ModerationResult {
  flagged: boolean
  flaggedCategories: string[]
}

export interface Settings {
  create_episodes: boolean
  create_episodes_count: number
  cleanup_episodes: boolean
  cleanup_episodes_keep: number
  cleanup_polls: boolean
  cleanup_polls_keep: number
  automatic_polls_enabled: boolean
  poll_comment_messages_enabled: boolean
  create_comments_count: number
  poll_wait_minutes: number
  poll_min_episodes: number
  moderation_max_value: number
}

export interface DiscordConfig {
  bot: {
    url: string
    token: string
  }
  announce: {
    guildId: string
    channelId: string
  }
}

export interface WordsInterface {
  getRandomPhrase: () => string
  getRandomNoun: () => string
  getRandomAdjective: () => string
  getRandomObject: () => string
  getRandomAnimal: () => string
  getRandomCulture: () => string
  getRandomFood: () => string
  getRandomLocation: () => string
  randomWordTopic: () => string
  randomWordChaos: () => string
  randomMonologAction: () => string
  randomDialogAction: () => string
  randomAction: () => string
  randomUniqueness: () => string
  randomAnimeFiller: () => string
  randomWordPrompt: () => string
  nonSpecificPrompt: () => string
}

// @see https://github.com/SortableJS/vue.draggable.next
// @see https://github.com/SortableJS/Sortable#event-object-demo
export interface DragEndEvent {
  item: HTMLElement // dragged HTMLElement
  to: any[] // target list
  from: any[] // previous list
  oldIndex: number // element's old index within old parent
  newIndex: number // element's new index within new parent
  oldDraggableIndex: number // element's old index within old parent, only counting draggable elements
  newDraggableIndex: number // element's new index within new parent, only counting draggable elements
  clone: any // the clone element
  pullMode: any
}

export enum ApiType {
  OPEN_AI = 'openai',
  ANTHROPIC_AI = 'anthropicai',
}

export enum WsPath {
  VN = '/ws/vn',
  EPISODES = '/ws/episodes',
}

export enum LogType {
  GENERAL = 'GENERAL',
  CHAT_GPT_REQUEST = 'CHAT_GPT_REQUEST',
  CHAT_GPT_RESPONSE = 'CHAT_GPT_RESPONSE',
  CHAT_GPT_EXCEPTION = 'CHAT_GPT_EXCEPTION',
  COQUI_TTS_SYNTH_REQUEST = 'COQUI_TTS_SYNTH_REQUEST',
  COQUI_TTS_EXCEPTION = 'COQUI_TTS_EXCEPTION',
}
